import React, { useEffect, useState } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';

import LinkButton from "./subcomponents/LinkButton";
import dreamLinks, {dreamQuestLegend} from "../data/dream-menu";

//import "./Buildings.css";
import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
// import Oops from "./subcomponents/Oops";
// import Land from "./Land";

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function TableRow (props) {
  const {data, header} = props;
  // console.log("data", Object.keys(data.task).toString());
  
  if (data.link !== undefined) {
    // console.log("link", data.link)
    return <tr>
    <td colSpan={"3"}>
      <Link to = {data.link.link}>
        <img className = "dream-level" src = {"/images/dream/"+ data.link.name+".png"} alt = {data.link.name} />
        Further {data.link.name} quests
        <img className = "building-level" src = "/images/dream/link-arrow.png" alt = "Link Arrow"/></Link>
       
    </td>
  </tr>}

  return (<tr className = "notranslate">
    {header.map((col, i) => {         // map each item in the header
      //console.log("Data cell", i);
      if (data[col] === 999) {
        data[col] = "?"
      }
      
      if (data[col] === undefined) {    // this data type does not exist, return an empty cell
        return <td key = {i}> </td>
      } else if (col === "unlock") {  // the unlock has special formating
        return <td key = {i}> 
          <div className="unlock-container">
            <img className="unlock-xp-icon" src="/images/dream/xp.png" alt={col}></img>
            <p className="unlock-xp">{data[col]}</p>
          </div>
        </td>
      } else if (typeof data[col] === "object") {
          const items = data[col];
          
          return <td key = {i}> {
            Object.keys(items).map((key, i) => {
              items[key] === 999 && (items[key] = "?");
              
              return <React.Fragment key = {i} >
                {(typeof items[key] === "string" && items[key].includes('-')) ? 
                <span>
                  <img className = "value-table-legend" src = {"../../images/dream/actions/" + items[key].split('-')[0] + ".png"} alt = {items[key].split('-')[0]}/>
                  {numberWithCommas(items[key].split('-')[1])}
                  <img className = {items[key].split('-').includes('build') ||  items[key].split('-').includes('upgrade') ? "dream-level" : "building-level"} src = {"../../images/dream/"+ key.trim() +".png"} alt={key}></img>
                </span>:  
                <span>
                  {numberWithCommas(items[key])}
                  <img className = "building-level" src = {"../../images/dream/"+ key.trim() +".png"} alt={key}></img>
                </span>}
                <br />
                
              </React.Fragment>
            })
          }</td>
      } else {
        return <td key={i}> {data[col]}<img className = "building-level" src = {"../../images/buildings/"+ col +".png"} alt={col}></img> </td>
      }
      

    })}
  </tr>)
}

// ************** Main Function *****************
export default function DreamQuests () {

  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])

// **************************************************************************************************************************************  
  const [building, setBuilding] = useState();
  
  useEffect(()=>{
    axios.get('/api/dream/quest')
      .then(res => {
        // console.log("results from database:", res.data);
        setBuilding(res.data);
      });
  },[]);  

  if (building === undefined) {
    return<Loading text = "Loading quests..." />
  } 

  let headerList = [];
  const header = building.levels[0];
  Object.keys(header).map((key, i) => {
    headerList.push(key);
    return null;
  });

  Meta(`Dream Farm Quests - bfmhfansite.com`, `All of the mascot coin quests for Big Farm Mobile Harvest's Dream Farm.`);
  return (
    
    // <div className = "building-tables" >
    <article className = "dream-calc-page">
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {dreamLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
      {/* ********* Table Name ********** */}
      
      
      <article className = "blog-post">
        <h2 className = "blog-title"><img className="building-large" src={"../../images/dream/quest.png"} alt="Dream Quest Book"></img><span style={{color: "#D98D95"}}>Dream</span> Quest book</h2>
        <p>There are 5 quest lines in this event.  On this page, you will find the primary quest line introducing buildings and features.  For the <Link to = "/dream/buildings/farmhouse">farmhouse</Link>, <Link to = "/dream/buildings/barn">barn</Link> and <Link to = "/dream/buildings/silo">silo</Link> quest lines, the upgrading rewards are combined into the building tables.  The final one is a <Link to = "/dream/market">market</Link> quest line.</p>  
        {dreamQuestLegend.map((item, i) => {
          return <p key = {i}>
            {item.pic && <img className = "value-table-legend" src={"../../images/dream/" + item.pic +".png"} alt = "xp"/>}
            {item.blurb}
            
            </p>
        })}
        

      
        <div style = {{"display": "block", "Postition": "relative"}} >
          <div className = "scroller-header" >
            <img className="building-large" src={"../../images/dream/quest.png"} alt= "Dream Quest Book"></img>
            <h2 className = "building-name"><span style={{color: "#D98D95"}}>Dream</span> Quest Book</h2> 
            {/* {building.levels.length < 3 && <p className = "watermark-sm">bfmhfansite.com</p>} */}
          </div>
          
          
          {/* ********** Table ************** */}
          <div className = "building-table-overflow">

            
            <table id= "buildings" className = "building-table">
              <thead>
                <tr>
                  {headerList.map((title, i) => {return <th key = {i}>{(title.charAt(0).toUpperCase() + title.slice(1)).replace("-", " ")}{(i === 0 && building.levels.length > 2) && <p className = "watermark-building-complex">bfmhfansite.com</p>}  </th>})}
                </tr>
              </thead>  
              {/* <tbody> */}
              {/* <tbody className = "notranslate"> */}
              <tbody>
                {building.levels.map((level, i) => <TableRow key = {i} data = {level} header = {headerList} />)}
              </tbody>
            </table>
            
          </div>

        </div>  
      </article>  
    </article>
  )

}
