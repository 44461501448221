import React,{useEffect} from "react";
// import { useLocation } from "react-router-dom";
import './Ad.css';

export default function AdComponent({slot}) {
    
    // const location = useLocation();
    // console.log("Location", location);

    useEffect(()=>{
        setTimeout(()=>{
            
            (window.adsbygoogle = window.adsbygoogle || []).push({})    
            // console.log("This happens 1 second after the load")
        }, 10)
        // (window.adsbygoogle = window.adsbygoogle || []).push({})
    },[])

    const isSmall = window.innerWidth < 365

    return <div className = "mobil e-ad-wrapper">
        
        {/* {isSmall ? 
            <div className = "test-ad ad300250">
                <h4>Small 300x250</h4>
            </div>:
            <div className = "test-ad ad336280">
                <h4>Medium 336x280</h4>
            </div>
        } */}
        {isSmall ? 
            <ins className="adsbygoogle"
                style= {{display: "inline-block", width: "300px", height: "250px"}}
                data-ad-client="ca-pub-6837707729833975"
                data-ad-slot="3812843840">
            </ins>:
            <ins className="adsbygoogle"
                style= {{display: "inline-block", width: "336px", height: "288px"}}
                data-ad-client="ca-pub-6837707729833975"
                data-ad-slot="6760509184">
            </ins>
            }
    </div>
}