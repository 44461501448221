import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
// import postTags, {postBooks} from '../data/post-tags';
import postTags from '../data/post-tags';
import Loading from './subcomponents/Loading';

// import "./Blog.css";
import Meta from './subcomponents/Meta';

export default function Post () {
  
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState(postTags);
  // const [difficulty, setDifficulty] = useState(postBooks);
  const [filteredPosts, setFilteredPosts] = useState([]);
  
  // const [isFilterVisible, setIsFilterVisible] = useState(localStorage.getItem("isPostFilterVisible") ? (localStorage.getItem("isPostFilterVisible") === "true" ? true : false) : true );
  const isDifVis = false;
  
  // tags as an object of boolean
  // filter
  // forEach(tag => tag.key = true) then include, or seach next
  // dispay
  // map tags, apply css by value
  
  useEffect(()=>{
    Meta("Posts - bfmhfansite.com", "Big Farm Mobile Harvest Fansite Table of Posts.  A growning collection of posts on game mechanices, guides, game dynamics, better farming, building a better co-op, or life improvement in general.")
	  // axios.get('api/blog/edit')
    axios.get('api/blog/')
      .then(res => {
        // console.log("Datebase results", res.data)
        setPosts(res.data);
        setFilteredPosts(filterPosts(res.data));
   
      });
  // eslint-disable-next-line
  },[]);

  useEffect(() =>{
    setFilteredPosts(filterPosts(posts));
    // eslint-disable-next-line
  }, [tags])
// }, [tags, difficulty])

  const filterPosts = (postsToFilter) => { 
    let returnedPosts = [];
    if (tags[tags.length-1].active) {
      returnedPosts = [...postsToFilter.sort((a,b)=> a.sort - b.sort)]
    } else {
      postsToFilter.forEach(post => {
        tags.every(tag => {                                                               // .every runs through every element until a false is returned.  Pushes first match and then exits loop.  (Subsequent filter matches don't matter.)
          // console.log("post", post.title, post.tags, "tag", tag.tag)  
          if (tag.active && post.tags.includes(tag.tag)) {
            returnedPosts.push(post)
            // console.log("match", post.title)
            return false
          }
          return true
        })
      })
    }
    
    
    returnedPosts = [...returnedPosts.sort((a,b)=> a.sort - b.sort)]
    
    // const currentFilter = tags.filter(tag => tag.active)[0];
    
    // if (currentFilter.tag !== "all") {
    //   returnedPosts = [...postsToFilter.filter(post => post.type === currentFilter.tag).sort((a,b)=> a.sort - b.sort)]
    // } else {
    //   returnedPosts = [...postsToFilter]
    //   // returnedPosts = [...postsToFilter.sort((a,b)=> a.sort - b.sort)]
    // }
    
    // const currentDifficulty = difficulty.filter(level => level.active)[0];
    // // console.log("Current Book", currentDifficulty)
    // if (currentDifficulty.tag !== 0) {
    //   // console.log("Current Book", currentDifficulty)
    //   returnedPosts = [...returnedPosts.filter(post => post.difficulty !== undefined && post.difficulty.includes(currentDifficulty.tag))]
    // }
    return returnedPosts
  }

  function BlogCard (props) {
    // console.log(props.difficulty)
    return(<div className = "card-preview">
        {props.cover && <Link to = {"/blog/" + props.link}><img className = "cover-img" src = {props.cover} alt = "Blog Preview" ></img></Link>}
        {/* <img className = "cover-img" src = "/images/blog/game-tips.png" alt = "Blog Preview" ></img> */}
        <div className = "blog-card-wrapper" >
          <h2 className = "title">{props.title}</h2> 
          {isDifVis && props.difficulty !== undefined && <p>Difficulty:  
            {props.difficulty.map((book, i) => <span key = {i}><img key = {i} className = "blog-difficulty" src = {"/images/blog/book-uni-"+ book +".png"} alt = "University Book"></img></span>)}
          </p>}
          {/* {props.difficulty !== undefined && <p>Difficulty: {props.difficulty.map((book,i)=> {<img key = {i} className = "blog-difficulty" src = "/images/blog/book-uni-1.png" alt = "University Book"></img>})}</p>} */}
          {/* <p>Difficulty: <img className = "blog-difficulty" src = "/images/blog/book-uni-1.png" alt = "University Book"></img></p> */}
          <p className = "excerpt">{props.excerpt}</p>
          <Link  className = "excerpt" to={"/posts/" + props.link}>Read More...</Link> 
        </div>
        
            
    </div>)
  }

  const handleChangeTags = (e) => {                                           // radio, sets single post tag
    const newState = [...tags];
    newState.forEach((button, i) => {
      button.active = false;
      if (button.name === e.target.name) {
        // console.log(button)
        newState[i].active = true;
      };
    });
    setTags(newState);
  }

  // const handleChangeDifficulty = (e) => {
  //   console.log("Difficulty Change - target:", e.nativeEvent.target)
  //   const newState = [...difficulty];
  //   newState.forEach((button, i) => {
  //     button.active = false;
  //     if (button.name === e.target.name) {
  //       console.log(button)
  //       newState[i].active = true;
  //     };
  //   });
  //   setDifficulty(newState);
  // }

  if (posts.length === 0) return <Loading text = "Loading posts..." />
  // console.log("filtered posts to display", filteredPosts)
  // console.log("Difficulty filter free running: ", difficulty)
  
  return(
    <main id = "blog-main">
      <h3>Posts</h3>

      {/* <p className = "blurb">This is where you will find more reflective style posts on game dynamics, better farming, co-op building and life improvement.  More games mechanics specific items can be found in the <Link to = "/guides">guides</Link> section.</p> */}
      {/* <br/> */}
      <div className = "filter-preview">
        <div>
          {tags.map((button, i) => {
            return <button name = {button.name} className = {button.active ? "blog-button-active blog-button" : "blog-button-inactive blog-button"} key = {i} onClick = {handleChangeTags}>
              {button.name}
            </button> 
          })}
          {tags.map((blurb, i) => blurb.active && <p key = {i} style = {{textAlign: "center"}}>{blurb.blurb}</p>)}

{/* Uncomment these two sections for fitlers.*/}

          {/* <button className = "blog-button" onClick = {() => {
            localStorage.setItem("isPostFilterVisible", !isFilterVisible)
            setIsFilterVisible(preVal => !preVal)
          }}>Filters <img className = "button-icon" src={"/images/menu/" + (isFilterVisible? "less": "more") + ".png"} alt = "More button"/></button> */}
          {/* <div style = {{display: (isFilterVisible? "inline": "none")}}>
            {isDifVis && <div>
              {difficulty.map((button, i) => {
                // console.log("Button name: ", button.name)
                return <button name = {button.name} className = {button.active ? "blog-button-active blog-button" : "blog-button-inactive blog-button"} key = {i} onClick = {handleChangeDifficulty}>
                  {button.name} <img name = {button.name} className = "blog-difficulty" src = {"/images/blog/book-uni-"+ button.tag +".png"} alt = "University Book"></img>
                </button> 
              })}
            </div>}
          </div> */}
        
        </div>



      </div>
      {/* <div>
        {tags.map((button, i) => {
          return <button name = {button.name} className = {button.active ? "blog-button-active blog-button" : "blog-button-inactive blog-button"} key = {i} onClick = {handleChangeTags}>
            {button.name}
          </button> 
        })}
      </div> */}
      
      <section className = "preview">
        {/* {filteredBlogs.map((blog, i) => { */}
        {filteredPosts.length === 0 && <div>
          <p>No posts found.  Please check filters.</p>                       
        </div>}                                                                    {/* Update this section */}
        {filteredPosts.map((blog, i) => {
          return(<BlogCard
            key = {i}
            title = {blog.title}
            excerpt = {blog.excerpt}
            link = {blog.link}
            cover = {blog.cover}
            difficulty = {blog.difficulty}
          />)
        })}
      </section>
        
      <div className = "menu">
      </div>
    </main>    
  );
}