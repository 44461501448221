import React, { useEffect, useState } from 'react';
import LinkButton from "./subcomponents/LinkButton";
import dreamLinks from "../data/dream-menu";
// import {Link} from 'react-router-dom';
import axios from 'axios';
import Oops from './subcomponents/Oops';
import InnerPage from './subcomponents/InnerPage';
import Loading from './subcomponents/Loading';

// import "./Blog.css";
// import Meta from './subcomponents/Meta';

//get html
//set meta
//display menu
//set inner html

export default function DreamGuide () {
  
  const [page, setPage] = useState({
    title: "Loading...",
    html: "Please Wait"
  });
  
  useEffect(()=>{
    axios.get('/api/blog/dream-guide-rfdie3xueso')
    .then(res => {
      setPage(res.data[0]);
    });
      
  },[]);
  
  if (page === undefined) {
    // no blog was found
    // REPLACE THIS
    return <Oops text = "That page doesn't seem to exist...  Check the link and try again.  I'll take you back to the home page" link="/"/>

    // console.log("Blog does not exist");
    // return <Redirect to="/blog" />
  } else if (page.title === "Loading...") {
    return <Loading text = "Loading Page..." />

  } else {
    // console.log(page)
    return (
        <main id = "blog-main">
            {/* ********** Navbar (Quick Links) ************ */}
            <nav className="menu-content building-menu">
                {dreamLinks.map(link => {
                return(<LinkButton 
                    key={link.key}
                    name={link.name}
                    link={link.link}
                />)
                })}
            </nav>
            <InnerPage blog = {page} />    
        </main>
    
    )
    
  }
}

  
  
  