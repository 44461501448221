import React, { useEffect, useState } from "react";
import axios from 'axios';

import LinkButton from "./subcomponents/LinkButton";
import dreamLinks from "../data/dream-menu";
import dreamCalcData, {calc_date, update_reason, calc_crit_date} from "../data/dream-calc-data";


import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
import AdComponent from "./AdComponent";



function numberWithCommas(x) {
  if (x === undefined) x = "?"
  if (x === 999) return "?"
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

const NumWithImg = (props) => {return <td>{numberWithCommas(props.num)}<img className = "building-level" src = {"../../images/dream/" + props.img + ".png"} alt= {"dream "+ props.img}></img></td>}


export default function DreamCalc () {

  // console.log("Dream Buildings - we got here")

  useEffect(()=>{          //reset display
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])


// **************************************************************************************************************************************  
  const [buildings, setBuildings] = useState();
  const [userData, setUserData] = useState(localStorage.getItem("dream_calc") ? JSON.parse(localStorage.getItem("dream_calc")): [...dreamCalcData]);
  const [update, setUpdate] = useState(false);
  const [critUpdate, setCritUpdate] = useState(false);
  const [isAd, setIsAd] = useState(false);
  
  useEffect(()=>{                                                    // check to see if they have current version and update tag
    // console.log("Date on file", localStorage.getItem("calc_date"))
    if (!localStorage.getItem("calc_date")) {
      // console.log("no date found");
      localStorage.setItem("calc_date", new Date().toISOString());
    } else {
      if (new Date(localStorage.getItem("calc_date")) < new Date(calc_crit_date)) {
        // console.log("Critical update")
        localStorage.setItem("calc_date", new Date().toISOString())
        setUserData([...dreamCalcData]);
        localStorage.setItem("dream_calc", JSON.stringify([...dreamCalcData]))
        setCritUpdate(true);
      } else if (new Date(localStorage.getItem("calc_date")) < new Date(calc_date)) {
        setUpdate(true);
        console.log("Minor update")
      }
    }
  },[]) 

  useEffect(()=>{                                             //get buildings and set concerned ones
    axios.get('/api/dream/buildings')
      .then(res => {
        // console.log("results from database", res.data);

        let buildingsToSave = {};
        userData.forEach((data, i) => {
          const filteredBuilding = res.data.filter(building => building.link === data.ref)[0];
          let levels = [...filteredBuilding.levels];
          if (data.ref === "land") {
            levels.forEach((level, j) => {
              // console.log("each level", levels[j]["cost-cash"])
              levels[j].cost = levels[j]["cost-cash"];
              delete levels[j]["cost-cash"];
            })
            levels = [{expansion: 6, cost: {cash: 0}}, ...levels]
            // console.log("fixed levels", levels)
          } 
          const rawData = {
            [data.ref]: {
              levels: levels,
              name: filteredBuilding.name
            }
          }
          
          // console.log("Just this building", rawData)
          buildingsToSave = {...buildingsToSave, ...rawData}
          // console.log("All together", buildingsToSave )
        })

        // console.log("All together", buildingsToSave )
        setBuildings(buildingsToSave);
        
      });
  // eslint-disable-next-line    
  },[]);  

  useEffect(()=>{                                                     // 70% chance to show ad
    setIsAd(() => (Math.random() >.3? true : false))
  },[])
  
  const TotalTable = () => {
    
    return (<div className = "dream-total-table">
      <h4 className = "total">Materials Needed</h4>
      <hr className = "calc-line" />
      
      <table  className = "dream-calc-table">
        <tbody className = "notranslate">
          <tr>
          <td >{numberWithCommas(costs.cash)}<img className = "building-level" src = {"../../images/dream/cash.png"} alt= "dream cash"></img></td>
          {/* colSpan = {"3"} */}
            <NumWithImg num = {costs.nail} img = "nail"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.board} img = "board"/><NumWithImg num = {costs.saw} img = "saw"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.brick} img = "brick"/><NumWithImg num = {costs.tile} img = "tile"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.glass} img = "glass"/><NumWithImg num = {costs.scroll} img = "scroll"/>
          </tr>
          <tr>
          <NumWithImg num = {costs.ink} img = "ink"/><NumWithImg num = {costs.book} img = "book"/>
          </tr>
        </tbody>
      </table>
    </div>
  )}

  
  const BarnInventory = (props) => {return <div className = "total-table-contract-container">
      
      <img className = "total-table-blank-contract" src = "../../images/dream/contract-blank.png" alt =""></img>
      <img className = "total-table-material" src = {"../../images/dream/" + props.img + ".png"} alt= {"dream "+ props.img}></img>
      <br/>
      <span className = "total-table-number notranslate">{numberWithCommas(props.num)}</span>
      
      </div>}
  const TotalTableBarn = () => {
  
    return (<div className = "dream-total-table">
      <h4 className = "total">Materials Needed</h4>
      <hr className = "calc-line" />
      
      <div className = "total-table-contract-container-cash">
        <img className = "total-table-blank-contract-cash" src = "../../images/dream/contract-blank-wide.png" alt =""></img>
        <img className = "total-table-material-cash" src = {"../../images/dream/cash.png"} alt= {"dream cash"}></img>
        <br />
        <span className = "total-table-number-cash notranslate">{numberWithCommas(costs.cash)}</span>
      </div>
      
      <br/>

      <BarnInventory num = {costs.scroll} img = "scroll"/>
      <BarnInventory num = {costs.ink} img = "ink"/>
      <BarnInventory num = {costs.book} img = "book"/>
      <br />
      <BarnInventory num = {costs.brick} img = "brick"/>
      <BarnInventory num = {costs.tile} img = "tile"/>
      <BarnInventory num = {costs.glass} img = "glass"/>
      <br />
      <BarnInventory num = {costs.nail} img = "nail"/>
      <BarnInventory num = {costs.board} img = "board"/>
      <BarnInventory num = {costs.saw} img = "saw"/>


      
      {/* <table  className = "dream-calc-table">
        <tbody className = "notranslate">
          <tr>
          <td >{numberWithCommas(costs.cash)}<img className = "building-level" src = {"../../images/dream/cash.png"} alt= "dream cash"></img></td>
          
          <NumWithImg num = {costs.nail} img = "nail"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.board} img = "board"/><NumWithImg num = {costs.saw} img = "saw"/><NumWithImg num = {costs.brick} img = "brick"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.brick} img = "brick"/><NumWithImg num = {costs.tile} img = "tile"/>
          </tr>
          <tr>
            <NumWithImg num = {costs.glass} img = "glass"/><NumWithImg num = {costs.scroll} img = "scroll"/>
          </tr>
          <tr>
          <NumWithImg num = {costs.ink} img = "ink"/><NumWithImg num = {costs.book} img = "book"/>
          </tr>
        </tbody>
      </table> */}
    </div>
  )}

  // ************ Individual Buildings ************* 
  const BuildingTable = (props) => {
    
    const {index} = props;
    const {allowZero, active, complete, isMultiplier, multiplier, ref, userLevel, userSpread} = props.data;

    const handleLevel = (e) => {
      e.preventDefault();
      const newUserData = [...userData];
      if (e.target.name === "up") {
        if (newUserData[index].userLevel < (buildings[ref].levels.length - (complete ? 1 : 3))) {
          newUserData[index].userLevel ++;  }
      } else {
        if (newUserData[index].userLevel > (allowZero ? -1 : 0)) newUserData[index].userLevel --;  
      }
      setUserData(newUserData)
      localStorage.setItem("dream_calc", JSON.stringify(newUserData))
    }
    const handleSpread = (e) => {
      e.preventDefault();
      const newUserData = [...userData];
      if (e.target.name === "up") {
        if (newUserData[index].userSpread < (ref === "farmhouse" ? 9 : 5)) newUserData[index].userSpread ++;  
      } else {
        if (newUserData[index].userSpread > 1) newUserData[index].userSpread --;    
      }
      setUserData(newUserData);   
      localStorage.setItem("dream_calc", JSON.stringify(newUserData))
    }
    const handleMultiple = (e) => {
      e.preventDefault();
      const newUserData = [...userData];
      // console.log ("before:", newUserData)
      if (e.target.name === "up") {
        if (newUserData[index].multiplier < newUserData[index].isMultiplier) newUserData[index].multiplier ++;  
      } else {
        if (newUserData[index].multiplier > 1) newUserData[index].multiplier --;    
      }
      setUserData(newUserData);   
      localStorage.setItem("dream_calc", JSON.stringify(newUserData))
    }
    const handleActive = (e) => {
      e.preventDefault();
      const newUserData = [...userData];
      newUserData[index].active = !newUserData[index].active;
      setUserData(newUserData);  
      localStorage.setItem("dream_calc", JSON.stringify(newUserData))
    }
    return <div className = "dream-building-table">

      <h4 className = "total"><img className = "dream-calc-title-img" src = {"/images/dream/"+ ref + ".png"} alt = {ref} />{buildings[ref].name}<button className = {active ? "dream-calc-active dream-calc-button" : "dream-calc-inactive dream-calc-button"} onClick = {handleActive}>{active ? "On" : "Off"}</button><br /></h4>
      {active && <div className = "dream-calc-guts" >
        <hr className = "calc-line" />  
        <div className = "dream-calc-adjust-div">
          <span className = "dream-calc-adjust-text">Current Level: </span>
          <button name = "down" onClick ={handleLevel} className="button">
            <img name = "down" className = "button-icon" src={"/images/menu/less.png"} alt="more button" />
          </button>
          <span className = "dream-calc-adjust-variable dream-calc-adjust-text"> {userLevel + (ref !== "land" ? 1 : 6)} </span>
          <button name = "up" onClick = {handleLevel} className="button">
            <img name = "up" className = "button-icon" src={"/images/menu/more.png"} alt="more button" />
          </button>
          <br/>
        </div>

        <div className = "dream-calc-adjust-div">
          <span className = "dream-calc-adjust-text">Future (levels): </span>
          <button name = "down" onClick ={handleSpread} className="button">
            <img name = "down" className = "button-icon" src={"/images/menu/less.png"} alt="more button" />
          </button>
          <span className = "dream-calc-adjust-variable dream-calc-adjust-text"> {userSpread} </span>
          <button name = "up" onClick = {handleSpread} className="button">
            <img name = "up" className = "button-icon" src={"/images/menu/more.png"} alt="more button" />
          </button>        
        </div>

        {isMultiplier && <div className = "dream-calc-adjust-div">
          <span className = "dream-calc-adjust-text">Quantity: </span>
          <button name = "down" onClick ={handleMultiple} className="button">
            <img name = "down" className = "button-icon" src={"/images/menu/less.png"} alt="more button" />
          </button>
          <span className = "dream-calc-adjust-variable dream-calc-adjust-text"> {multiplier} </span>
          <button name = "up" onClick = {handleMultiple} className="button">
            <img name = "up" className = "button-icon" src={"/images/menu/more.png"} alt="more button" />
          </button>
          <br/> 
        </div>}

        <hr className = "calc-line" />  

        {(userLevel + 1 >= buildings[ref].levels.length) ? <h4>Complete!</h4> : <React.Fragment>
            {(multiplier > 1) && <span className = "dream-calc-adjust-text" >Each Building</span>}
            <table  className = "dream-building-table">
              <tbody className = "notranslation">
                {buildings[ref].levels.map((level, i) => {
                    if ((i >= userLevel + 1) && (i < (userLevel + userSpread + 1))) { 
                      return <tr key={i}>
                        {ref === "land" ? 
                        <td> {level.expansion}<img className = "building-level" src = {"../../images/dream/expansion.png"} alt="dream farm expansion"></img></td>
                        :
                        <td> {level.level}<img className = "building-level" src = {"../../images/dream/level.png"} alt="dream farm level"></img></td>
                        }
                        <td>
                          {Object.keys(level.cost).map((item, j) => {
                            return <span key = {j}>
                            {numberWithCommas(level.cost[item])}<img className = "building-level" src = {"../../images/dream/"+ item +".png"} alt={item}></img><br/>
                            </span>
                          })}
                        </td>
                      </tr>
                    } else { return null}
                  })}      
              </tbody>
            </table>
          </React.Fragment>
        }
      </div>}
      
    </div>
  }
  
  if (buildings === undefined) {
    return<Loading text = "Loading the calculator..." />
  } 

  // ************** Calc the cost of all buildings included ****************
  let costs = {"nail": 0, "board": 0, "saw": 0, "brick": 0, "tile": 0, "glass": 0, "scroll": 0, "ink": 0, "book": 0, "cash": 0}

  userData.forEach((data, i) => {
    if (data.active) {
      buildings[data.ref].levels.forEach((level, i) => {
        if ((i >= data.userLevel+1) && (i < (data.userLevel + data.userSpread+1)))
          Object.keys(level.cost).forEach((item, j) => {
            if (level.cost[item] !== 999) {
              // console.log("Building: ", data.ref, data.isMultiplier, (data.isMultiplier !== false), "expression: ", (data.isMultiplier !== false ? data.multiplier : 1), "CM:", data.multiplier)
              costs[item] += ((level.cost[item]) * (data.isMultiplier !== false ? data.multiplier : 1))
            }
          })
      })
    }  
  })

  // console.log("Buildings", buildings)
  // console.log("Ad", isAd)
  
  Meta(`Dream Calculator - bfmhfansite.com`, `Dream Farm Building calculator to help you manage contracts and materials as you progess through the event`);

  if (critUpdate) {
    return <article className = "dream-calc-page">
      <div className = "dream-calc-card">
        <span className = "dream-calc-adjust-text">There was an update that required resetting the calculator.  Sorry for the inconvience.</span>
        <h4 className = "total"><button className = "dream-calc-active dream-calc-button" onClick = {()=> {
          setCritUpdate(false)
          }}>OK!</button><br /></h4> 
        <span className = "dream-calc-adjust-text">Changes made: {update_reason}</span>
      </div>
    </article>
  }
  return (
    
    <article className = "dream-calc-page">

      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {dreamLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
        
      {/* ********* Table Name ********** */}
      <h2 className = "building-name"><span style={{color: "#D98D95"}}>Dream Farm</span> Calculator</h2> 
      <br/><span className = "dream-calc-adjust-text">Instructions below</span>
      
      <div className = "dream-calc-card">     {/*   soft update    */}
        <h4>Changes to Land</h4>
        <span className = "dream-calc-adjust-text">Refresh the page to load the most up to date tables.  Please note Big Farm changed land.  Double check your expansion are listed in the table under the land adjustments.  "?" are not included in the total table.</span>
      </div>
      
      
      {update && <div className = "dream-calc-card">     {/*   soft update    */}
        <span className = "dream-calc-adjust-text">A new update is available for the calculator.  Updating will reset the calculator.</span>
        <h4 className = "total"><button className = "dream-calc-inactive dream-calc-button" onClick = {()=> {
          if (window.confirm("Are you sure you want to update and reset the calculator?")) {
            localStorage.removeItem("dream_calc");
            localStorage.removeItem("calc_date");
            window.location.reload();
          }
          }}>Update</button><br /></h4> 
        <span className = "dream-calc-adjust-text">Changes made: {update_reason}</span>
      </div>}

      {critUpdate && <div className = "dream-calc-card">   {/*  hard update */}
        <span className = "dream-calc-adjust-text">There was an update that required resetting the calculator.  Sorry for the inconvience.</span>
        <h4 className = "total"><button className = "dream-calc-active dream-calc-button" onClick = {()=> {
          setCritUpdate(false)
          }}>Thanks</button><br /></h4> 
        <span className = "dream-calc-adjust-text">Changes made: {update_reason}</span>
      </div>}

      <div className = "dream-calc-card">
        {window.innerWidth > 356 ? <TotalTableBarn /> : <TotalTable />} 
      </div>

      {/* {userData.map((data, i) => {
        return <div key = {i} className = "dream-calc-card"> 
          <BuildingTable key = {i} data = {data} index = {i}/>
        </div>
      })}    */}

      {userData.map((data, i) => {
        return <React.Fragment key = {i}>
            {i === 4 && isAd && <AdComponent/>}
            <div className = "dream-calc-card"> 
              <BuildingTable key = {i} data = {data} index = {i}/>
            </div>
          </React.Fragment>
      })} 

      <div className = "dream-calc-card">     {/** Reset calc*/}
        <span className = "dream-calc-adjust-text">Reset calculator</span>
        <h4 className = "total"><button className = "dream-calc-inactive dream-calc-button" onClick = {()=> {
          if (window.confirm("Are you sure you want to reset the calculator?")) {
            localStorage.removeItem("dream_calc");
            window.location.reload();
          }
          }}>Reset</button><br /></h4>
      </div>

      <div className = "dream-calc-card">                 {/* Instructions */}
          <h4 className = "total">Instructions</h4>
          <p>This calculator will tell you how many materials you need to gather (or have in stock) for the buildings you have selected to build.  
            You can flip between your inventory (barn), market, and this page to select and optimize contracts based on what you need, both now, and into the immediate future.</p>
          <p>Each building can be turned on and off.</p>
          <p>Current level: is what level your building is right now.</p>
          <p>Future (levels): is how many levels you want to look forward to gather materials for.</p>
          <p>If you are on farmhouse level 40, and you want to know the materials for levels 41, 42, 43, you would set this to 3 levels.</p>
          <p>Quantity: some buildings you will want to upgrade multiples of, like 2 mills or 4 fields.</p>  
          <p>This also means all of the same type of buildings will be considered the same level.</p>
          <p>The table for each building shows the next levels for reference.  It is for each individual building.</p>
          <p>This will be a test run for the calculator.  I will collect feedback and make adjustments afterwards.</p>
          <p>This calculator uses Local Storage (think cookies) to save your progress.  If you have a cookie blocker, the calculator will reset each refresh of the page.</p>
      </div>    

      <div className = "dream-calc-card">                 {/* Reason why */}
          <h4 className = "total">Why a Calculator?</h4>
          <p>The first time I played Dream Farm, I found I was adding up the materials I needed to upgrade buildings so I could better manage my contracts. 
            It sucks to trash brick contracts, do an upgrade, and then find out the next level needs all the bricks you trashed.</p>
          <p>I found this method to work fairly well, so I built an online tool where you can select core buildings, set your current building level, how many levels you want to look forward to gather supplies for, and how many buildings you want to upgrade if there are duplicates (like 4 fields to level 5). 
            The total materials needed to upgrade everything is displayed at the top. 
            Every time you upgrade the farmhouse, simply adjust your current level up to match and it will calculate the new materials for the next levels.</p>
      </div>    
      
    </article>
  )

}

