import React, { useState, useEffect } from "react";
import axios from 'axios';

import LinkButton from "./subcomponents/LinkButton";
import Loading from "./subcomponents/Loading";
import coopLinks from "../data/coop-menu";

import Meta from "./subcomponents/Meta";
import { Link } from "react-router-dom";
// import InfoButton from "./subcomponents/InfoButton";

export default function CoopProject () {

  const [projects, setProjects] = useState();
          
  const [columns, setColumns] = useState(Math.floor((window.innerWidth - 170)/120));
  const [visibleLevels, setVisibleLevels] = useState(calcLevels(columns));
  
  const [tractorLevel, setTractorLevel] = useState(.05);
  const [isTractor, setIsTractor] = useState(false);

  React.useEffect (() => {                  //monitors for resizes

    // console.log(window.innerWidth);
    const debouncedHandleResize = debounce(function handleResize() {
      const newColumns = Math.floor((window.innerWidth - 170)/120);
      setColumns(newColumns);
      setVisibleLevels(calcLevels(newColumns));
      
    }, 200);

    function handlePortrait () {
      if (window.orientation === 0) {
        //console.log(window.innerWidth);
        const viewportmeta = document.querySelector('meta[name=viewport]');
        viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0, minimum-scale=1.0");
      };
    }
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('orientationchange', handlePortrait);
    
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
      window.removeEventListener('orientationchange', handlePortrait);
      const viewportmeta = document.querySelector('meta[name=viewport]');
      viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0"); 
    }
  });

  useEffect(()=>{                    // Grabs the envents
    axios.get('/api/project')
      .then(res => {
        // console.log("Events from database", res.data);
        setProjects(res.data);
        //console.log(res.data);
      });
  },[]);  

  function calcLevels (col){
    //console.log("columns ", col, ", isRewards ", show)
    //console.log(window.innerWidth);
    let levels = [];
    switch (col) {
      case 1 :
        levels = [0]; 
        break;
      case 2 :
        levels = [0,1];
        break;
      case 3 :
        levels = [0,1,2];
        break;
      case 4: 
        levels = [0,1,2,3];
        break;
      default: 
        levels = [0,1,2,3,4];
        break;
    }
    return levels; 
  }

  //******** State Functions ********************* */
  function levelUp(){
    let newLevel = visibleLevels;
    if (visibleLevels[visibleLevels.length-1] < 4) {
      newLevel = [...visibleLevels, visibleLevels[visibleLevels.length-1] + 1];     // current array plus the next number
      newLevel.shift();                                                             // remove the first number
      setVisibleLevels(newLevel);
    }
  }

  function levelDown(){
    let newLevel = visibleLevels;
    if (visibleLevels[0] > 0) {
      newLevel = [visibleLevels[0] - 1, ...visibleLevels];
      newLevel.pop();
      setVisibleLevels(newLevel);  
    }
  }

  function LevelButton (props){
    return <button className= {"button " + props.class} onClick = {props.onClick}>
      <img className = "button-icon" src={"/images/co-op/" + props.pic + ".png"} alt= {props.alt} />
    </button>
  }

  if (projects === undefined) {   // data is not loaded yet.
    // console.log("Data is not loaded from database yet, put up loading screen");
    return <Loading text = "Loading projects..." />
  } 
  
     
  //************************* Master Function Return ******************************/
  
  Meta(`Co-op Projects - bfmhfansite.com`, `Every known co-op project level, collection and points awarded.  Table adjusts for daily bonus and village tractor bonus.  Big Farm Mobile Harvest.`); 
  
  return (
    <React.Fragment>
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {coopLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
    
      <h2 style = {{"margin": "12px"}}>Co-op projects</h2>
      <div className = "project-blurb">
        <img className = "pimage pth prt" src = "/images/co-op/co-op-flag.png" alt = "Co-op Project Flag"></img>
        <p>To contribute to a project, simply harvest the items required by the project when the project is running.  The items go into your barn and credit for harvesting them goes to the co-op.</p>
        <p>The flag shows the current items required.  Pictured is "Cheesed off!" after enough milk has been collected and cheese is still needed.</p>
        <img className = "pimage pum plt" src = "/images/buildings/loyalty-point.png" alt = "Loyalty Points"></img>
        <p>The highest 3 contributors get extra loyalty points on every project.  Milk, wheat, dandelions and almonds get double the contribution credit.  Everything else is 1 to 1 contribution credit.  (20 corn is 20 contribution points.)</p>
        <img className = "pimage pum prt" src = "/images/co-op/bonus-star.png" alt = "Bonus Co-op Points"></img>
        <p>The table below lists all of the projects and known levels.  Every day, one random (no pattern found yet) project has a multiplier which increases the amount of points.  Clicking in the bottom left of the project icon will show the bonus applied to the projects.  You can also use the <Link to = "/co-op/upgrades/points">village tractor</Link> to increase the points earned for a project.  You can turn the tractor on, off, and set it's bonus to see the effect in the table.</p>
        <p>Your tournament score is the sum of all the project points you complete while the tournament is active (every other week).</p>
      </div>  
      <div className = "theme-event-master">
        <div className = "project-header">
          <div className = "tractor-level">
            <button style = {{"width":"60px", "height": "60px", "margin": "0"}} className= "button" onClick = {()=>setIsTractor(!isTractor)}>
              <img style = {{"width":"50px", "height": "50px"}} className = "button-icon" src={"/images/co-op/" + (isTractor ? "tractor-show" : "tractor-hide") + ".png"} alt= "tractor" />
            </button>
  
            <input type="range" min="1" max ="20" step = "1" value = {tractorLevel*100} onChange ={e=>setTractorLevel(e.target.value/100)}></input>
            <img className = "project-sign" src={"../images/co-op/sign.png"} alt= "project sign" />
            <p>{Math.floor(tractorLevel*100)}%</p>
          </div>
          <div className = "table-levels">
            {!visibleLevels.includes(0) && <LevelButton pic="level-decrease" alt= "decrease level" onClick = {levelDown} />}  
            {/* <LevelButton pic={isBonus ? "hide-reward" : "show-reward"} alt = "toggle rewards" onClick = {toggleBonus} /> */}
            {!visibleLevels.includes(4) && <LevelButton pic="level-increase" alt= "increase level" onClick = {levelUp} />}
          </div>
        </div>
        
        <div style = {{"position": "relative"}}>
          {/* <img className = "theme-header-img" src={"../images/themes/" + event.link + "-header.png"} alt= {event.name} /> */}
          <p className = "watermark">bfmhfansite.com</p>
          <table id= "theme-table" className = "theme-table">
            <thead>
              <tr>
                <th style = {{"textAlign":"center"}}>Project</th>
                {visibleLevels.map((level, i) =>{return(<th key = {i} className = "left-border"><img className = "level-icon " src = {"/images/themes/star"+ (level+1) +".png"} alt={"level "+ (level+1)}></img></th>) 
                })}
              </tr>
            </thead>
            
            <tbody className = "notranslate">
              {projects.map((project, i)=>{
                return <ProjectRow key = {i} project = {project} visibleLevels = {visibleLevels} tractorFactor = {isTractor ? tractorLevel: 0}/>
              })} 
            </tbody>
          </table>
        </div>
      </div>    
      {/* </div> */}
    </React.Fragment>
  );
}

function ProjectRow (props) {

  const [isBonus, setIsBonus] = useState(false);
  const {project, visibleLevels, tractorFactor} = props;
  function toggleBonus(){setIsBonus(!isBonus)}

  return(<tr>
    <td className = "project-data">
      <img className = "project-icon" src = {"../images/co-op/"+ (project.name) +"-project.png"} alt={project.name + " project"}></img>
      <img className = "project-unlock-icon" src = {"/images/co-op/points.png"} alt = "Co-op Unlock Level"></img>
      <button style = {{"width":"48px", "height": "48px", "margin": "0"}} className= "bonus-button button" onClick = {toggleBonus}>
        {isBonus
        ?<img style = {{"width":"38px", "height": "38px"}} className = "button-icon" src={"/images/co-op/bonus-star.png"} alt= "Bonus Button" /> 
        :<img style = {{"width":"38px", "height": "38px"}} className = "button-icon" src={"/images/co-op/bonus.png"} alt= "Bonus Button" />}
        {isBonus && <span className = "project-multiplier">{project.multiplier}x</span>}
      </button>
      <span className = "project-unlock">{project.unlock}</span>
    </td>

    {visibleLevels.map((level, i)=> {
      return(<td style = {{"position":"relative"}} key = {i}>
        {Object.keys(project.collect).map((key, i) => {
          let amount = project.collect[key][level];
          if (amount === 999) {
            amount = "?"
          } 
          if (amount === 0) {
            return null
          } else {
            return (key !== "points" 
              
              ? <span key = {i}>{numberWithCommas(amount)}<img className = "task-icon " src = {"/images/co-op/"+ key +".png"} alt="Oops"></img><br /></span>
              
              : <span key = {i}>{numberWithCommas(amount === "?"?"?":amount*(isBonus ? project.multiplier : 1) + Math.ceil(amount*tractorFactor))}<img className = "task-icon " src = {isBonus ? "/images/co-op/bonus-star.png" : "/images/co-op/points.png"} alt="Oops"></img><br /></span>

              )
            }
          })}
      </td>)
    })}

  </tr>)
    

  
}

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
