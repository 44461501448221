const postTags = [
  {name: "Guides", active: true, tag: "guide", blurb: "Guides focus on core concepts of the game."},        // first one must be true
  {name: "Blogs", active: false, tag: "blog", blurb: "Blogs are reflective style posts on game dynamics, better farming, co-op building and life improvement."},
  // {name: "Co-op", active: false, tag: "co-op"},
  {name: "All", active: false, tag: "all", blurb: "All of the posts in one place."}
  
  // {name: "Speed Run", active: false, filter: "speed"}

];

export const postBooks = [
  {name: "All", active: true, tag: 0},
  {name: "Easy", active: false, tag: 1},
  {name: "Medium", active: false, tag: 2},
  {name: "Hard", active: false, tag: 3},
]

export default postTags;